<template>
  <div id="split-line">
    <div class="split_line_root_container">
      <div
        v-for="(el, i) in getValues"
        :key="i"
        class="split_line_root_container__item part_container"
        :class="[
          i === 0 ? 'left_border' : '',
          i === getValues.length - 1 ? 'right_border' : '',
        ]"
        :style="`width: ${el.width}%; background-color: ${el.color}`"
      >
        <div
          :title="`${el.main_title} ${el.sub_title}`"
          class="part_container__item part_container_title_container"
        >
          <div class="part_container_title_container__item main_title">
            <label>{{ el.main_title }}</label>
          </div>
          <div class="part_container_title_container__item sub_title">
            <label>{{ el.sub_title }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="describe_table"></div>
  </div>
</template>

<script>
export default {
  props: {
    colors: {
      type: Array,
      default() {
        return ["#4150b7", "#5bc367", "#f0ad4e", "#f44c4b", "#4150b7"];
      },
    },
    lineTitle: {
      type: String,
      default() {
        return null;
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      minWidth: 20,
    };
  },
  computed: {
    // unpack and transform inputs
    getValues() {
      const sumWeight = this.data.reduce((a, b) => a + b.weight, 0);

      return this.data.map((el, i) => {
        const width = (el.weight * 100) / sumWeight;
        const color =
          i > this.colors.length - 1
            ? this.colors[i % this.colors.length]
            : this.colors[i];

        return {
          main_title: el.main_title,
          sub_title: el.sub_title,
          width: width >= this.minWidth ? width : this.minWidth,
          color: color,
        };
      });
    },
  },
};
</script>

<style lang="less">
#split-line {
  .split_line_root_container {
    height: 40px;
    border: 1.5px solid #4150b7;
    border-radius: 10px;
    padding: 1px;
    display: flex;
    align-items: center;

    .part_container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: 4px 1px 4px 0px;

      &.left_border {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      }
      &.right_border {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
      }

      .part_container_title_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .main_title {
          color: white;
          font-size: 14px;
          //   text-shadow: 0.5px 0.5px 1px #0f0f0f31;
        }
        .sub_title {
          color: white;
          font-size: 11px;
          //   text-shadow: 0.1px 0.1px 0.1px #0f0f0fc9;
        }
      }
    }
  }
}
</style>
